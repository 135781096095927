import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { BrowserRouter as Router, Switch, Route, Routes, useNavigate } from 'react-router-dom';

import moment from 'moment';
import './../../App.css';
import Select, { components } from 'react-select';
import { collection, getDocs, doc, getDoc, Timestamp, query, where, } from 'firebase/firestore';
import { db } from './../../firebaseConfig';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/de';
import edit from './../../images/edit.svg';
import editWhite from './../../images/edit-white.svg';
import { getWeek, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { customStyles } from './../../helpers/StyleUtils';
import PropTypes from 'prop-types';
import ListViewAllBookings from '../SetCastUser/ListViewAllBookings';
import { vi } from 'date-fns/locale';

// Month names map
const monthNames = {
  'JANUAR': 0,
  'FEBRUAR': 1,
  'MÄRZ': 2,
  'APRIL': 3,
  'MAI': 4,
  'JUNI': 5,
  'JULI': 6,
  'AUGUST': 7,
  'SEPTEMBER': 8,
  'OKTOBER': 9,
  'NOVEMBER': 10,
  'DEZEMBER': 11
};

moment.locale('de')



//import 'moment/locale/de'; // Import the locale you want. In this case, German (Deutschland).

//const localizer = momentLocalizer(moment.locale('de')); // Set the locale to 'de' (German).
class CustomToolbar extends React.Component {
  render() {
    let { label } = this.props;
    let { currentView } = this.props;

    // Extract the month and start day from the label.
    const month = label.split(" ")[0].toUpperCase();
    const day = label.split(" ")[1].split("–")[0];

    // Construct the date using the extracted day and month.
    let date = new Date();
    date.setMonth(monthNames[month]);
    date.setDate(day);
    date = startOfWeek(date);

    // Get the week number using date-fns
    let weekNumber = getWeek(date);

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
        <div>
          <button className='button' onClick={() => this.navigate('TODAY')}>today</button>
          <button className='button' onClick={() => this.navigate('PREV')}>Prev</button>
          <button className='button' onClick={() => this.navigate('NEXT')}>Next</button>
        </div>
        <div className='weeknumber-wrapper'>
          <label>Week {weekNumber}: <strong>{label}</strong></label>
        </div>
        <div>
          <button className='button' onClick={() => this.view('week')}>Week</button>
          <button className='button' onClick={() => this.view('agenda')}>Day</button>
          <button className='button' onClick={() => this.view('month')}>Month</button>
        </div>
      </div>
    );
  }

  navigate = action => {
    const { date , currentView} = this.props;  // current date from the Calendar's props
    let newDate;


    switch (action) {
      case 'PREV':
        newDate = moment(date).subtract(1, 'month');
        console.log(currentView);
        break;
      case 'NEXT':
        newDate = moment(date).add(1, 'month');
        break;
      case 'TODAY':
        newDate = moment();
        break;
      default:
        newDate = date;
    }
  


    // Notify the parent component
    this.props.onNavigate(action, newDate.toDate());

  // Notify about the week change
  if (currentView !== 'month') {
    // Notify about the week change only if the view is not 'month'

    this.props.onWeekChange(startOfWeek(newDate.toDate()), endOfWeek(newDate.toDate()));
  }
  }



  view = viewType => {
    this.props.onChangeView(viewType);
  }
}

const localizer = momentLocalizer(moment);

class AllBookingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      loading: false,
      startOfWeek: startOfWeek(new Date()), // default to current week
      endOfWeek: endOfWeek(new Date()),
      currentDate: new Date(), // This will represent the current week's start date
      selectedUserEmail: 'all',
      selectedBookingStatus: '',
      userList: [],
      view: 'calendar',
      currentView:'month',
      filteredBookings: [], 
      locationOptions: [], // Holds the bundled location options
      selectedLocation: null, // Tracks the selected location
    };
    this.handleUserSelection = this.handleUserSelection.bind(this);
    this.handleBookingStatusSelection = this.handleBookingStatusSelection.bind(this);
    this.applyFilters = this.applyFilters.bind(this);

  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the week has changed
    if (prevState.startOfWeek !== this.state.startOfWeek || prevState.endOfWeek !== this.state.endOfWeek) {
      this.fetchBookings();
    }
  }

  componentDidMount() {
    this.fetchBookings();
    this.fetchUserList();
    this.fetchLocations(); 
  }
  changeView = (viewType) => {
    this.setState({ currentView: viewType });
  }
  onNavigate = (action, newDate) => {
    // Check the current view to determine how to set the state
    if (this.state.currentView === 'month') {
      // If the current view is month, set the state to the start and end of the month
      this.setState({
        startOfWeek: startOfMonth(newDate),
        endOfWeek: endOfMonth(newDate),
        currentDate: newDate // Update the current date when navigating
      });
    } else {
      // For week view or other views, keep the original behavior
      this.setState({
        startOfWeek: startOfWeek(newDate),
        endOfWeek: endOfWeek(newDate),
        currentDate: newDate
      });
    }
  }

  onWeekChange = (start, end) => {
    this.setState({
      startOfWeek: start,
      endOfWeek: end
    }, this.fetchBookings);  // Fetch bookings after the week is changed
  }


  fetchTalent = async (talentId) => {
    const talentDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/talents_new_data', talentId); // replace 'talents' with your actual talents collection name
    const talent = await getDoc(talentDoc);

    if (talent.exists) {
      return talent.data().Name; // assuming the talent document has a 'name' field
    } else {
      return 'no name';
    }
  }

  fetchCompany = async (companyID) => {
    const companyDoc = doc(db, 'SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1', companyID); // replace 'talents' with your actual talents collection name
    const company = await getDoc(companyDoc);

    if (company.exists) {
      return company.data().name; // assuming the talent document has a 'name' field
    } else {
      return 'deleted company';
    }
  }

  fetchUserList = async () => {
    // Assuming you have a users collection where user documents have a role field
    const userCollectionRef = collection(db, "/SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/UserData");

    // Query for superadmin
    const qSuperAdmin = query(userCollectionRef, where("role", "==", "superadmin"));
    const superAdminSnapshot = await getDocs(qSuperAdmin);

    // Query for setCastEditor
    const qSetCastEditor = query(userCollectionRef, where("role", "==", "setCastEditor"));
    const setCastEditorSnapshot = await getDocs(qSetCastEditor);

    // Merge the results
    const userList = [...superAdminSnapshot.docs, ...setCastEditorSnapshot.docs].map(doc => {
      const data = doc.data();
      return { email: data.email, name: data.email };  // Assuming each user has an email and name field
    });
    userList.unshift({ email: 'all', name: 'All' });

    this.setState({ userList });
  };


  handleLocationSelection = (selectedOption) => {
    this.setState({ selectedLocation: selectedOption }, () => {
        this.applyFilters();
    });
  };
  handleUserSelection(selectedUser) {
    this.setState({ selectedUserEmail: selectedUser.value }, () => {
      this.fetchBookings();
    });
  }
  handleBookingStatusSelection = (selectedStatus) => {
    this.setState({ selectedBookingStatus: selectedStatus ? selectedStatus.value : null }, () => {
      this.fetchBookings();
    });
  }
  fetchBookings = async () => {
    this.setState({ loading: true });
    console.log("Fetching bookings...");

    const startTimestamp = Timestamp.fromDate(moment(this.state.startOfWeek).subtract(30, 'days').toDate());
    const endTimestamp = Timestamp.fromDate(this.state.endOfWeek);
    
    const { selectedUserEmail } = this.state;
    const { selectedBookingStatus } = this.state;
    const bookingCollectionRef = collection(doc(db, 'SetCast CTA GmbH', 'bIETrNIclVpe0eINr1Z1'), 'Bookings');

    // Query based on start_date and selected user email for processingBy

    let initialQuery;


      // If 'All' is selected, ignore the processingBy filter
      initialQuery = query(
        bookingCollectionRef,
        where("start_date", ">=", startTimestamp)
      );

    let q = initialQuery;
 // Extend query with status if selectedBookingStatus is neither null nor 'All'
 // Extend the query with the status filter only if selectedBookingStatus is not null or 'All'
 if (selectedBookingStatus && selectedBookingStatus !== 'All') {
  q = query(
    q,
    where("status", "==", selectedBookingStatus)
  );
}

    const processingBySnapshot = await getDocs(q);

    const filteredDocs = processingBySnapshot.docs
    .filter(doc => {
        const status = doc.data().status;
        return status !== 'canceled' && status !== 'deleted' && status !== 'talentOnHold'; // Exclude both canceled and deleted events
    })
    .filter(doc => {
        const data = doc.data();
        console.log("Filtering data:", data);
        return data; // Assuming there is additional filtering logic you want to keep
    });


    const bookings = filteredDocs.map(doc => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
      };
    });
    // Now filter results client-side based on end_date
    /*const bookings = bookingSnapshot.docs.filter(doc => {
        const data = doc.data();
        console.log("Filtering data:", data); // 3. Inside the Filter Function
        return data.end_date && data.end_date.toDate() <= endTimestamp.toDate();
    }); */
    console.log("Processing bookings:", bookings);

    const events = await Promise.all(bookings.map(async (booking) => {
      const results = await Promise.allSettled([
        this.fetchTalent(booking.talentId),
        this.fetchCompany(booking.companyID)
      ]);

      const talentName = results[0].status === 'fulfilled' ? results[0].value : 'New request';
      const companyName = results[1].status === 'fulfilled' ? results[1].value : 'Deleted company';
      const start = booking.start_date ? moment(booking.start_date.toDate()) : null;
      const end = booking.end_date ? moment(booking.end_date.toDate()) : null;


      const clientName = booking.client ? booking.client.label : 'NULL';
      return {
        start: new Date(booking.start_date.seconds * 1000),
        end: new Date(booking.end_date.seconds * 1000),
        bookingId: booking.id,
        group:booking.group,
        groupColor:booking.groupColor,
        id: booking.id,
        boktitle: booking.title,
        set: booking.set,
        kpi: booking.kpi,
        talent: talentName,
        title: `Booking ${talentName} for ${companyName} (${booking.status})`,
        client: clientName,
        allDay: true,
        option: booking.option,
        status: booking.status,
        company: companyName,
        showDetail: false,
        proposedBy: booking.proposedBy,
        position: booking.position?.label ?? '',  // Using optional chaining and nullish coalescing
        positionwlabel: booking.position ?? {}, 
        startTime:booking.startTime,
        endTime:booking.endTime,
        location:booking.location,
      };
    }));

    console.log("Final events:", events); // 4. After All Transformations

    this.setState({ events, loading: false }), this.applyFilters();


  };

  fetchLocations = async () => {
    const locationSnap = await getDocs(collection(db, "SetCast CTA GmbH/bIETrNIclVpe0eINr1Z1/locations"));
    const locationData = locationSnap.docs.map(doc => ({
        city: doc.data().city,
        short: doc.data().short
    }));
  
    const cityBundles = locationData.reduce((acc, loc) => {
        if (!acc[loc.city]) {
            acc[loc.city] = [];
        }
        acc[loc.city].push(loc.short);
        return acc;
    }, {});
  
    const locationOptions = Object.keys(cityBundles).map(city => ({
        value: cityBundles[city], // array of shorts
        label: city + ` (${cityBundles[city].join(', ')})`
    }));
  
    this.setState({ locationOptions });
  };

  applyFilters = () => {
    const { events, selectedLocation } = this.state;
    let filteredBookings = events;



   // Filter by location
   if (selectedLocation) {
    // Filter bookings where the location is one of the 'shorts' in the selected city bundle
    filteredBookings = filteredBookings.filter(booking =>
        selectedLocation.value.includes(booking.location)
    );


  }
  console.log("this are filtredbookings to populate")
  console.log(filteredBookings);
  console.log("this are filtredbookings to populate")
  
      this.setState({ filteredBookings });
}

  handleSelectEvent = (event) => {
    const bookingId = event.bookingId;
    // Do something with the bookingId...
  };


  handleEventMouseEnter = (eventId) => {
    const updatedEvents = this.state.events.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: true }; // Set showDetail to true for the hovered event
      } else {
        return { ...event, showDetail: false }; // Set showDetail to false for all other events
      }
    });

    this.setState({ events: updatedEvents });
  };
  handleEventMouseLeave = (eventId) => {
    const updatedEvents = this.state.events.map((event) => {
      if (event.id === eventId) {
        return { ...event, showDetail: false }; // Set showDetail back to false for the event when mouse leaves
      }
      return event;
    });

    this.setState({ events: updatedEvents });
  };


  Event = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
      statusColor = '#15a326';
      break;
    case 'new order':
      statusColor = '#ffffff';
      break;
      case 'requested':
      statusColor = '#FF8806';
      break;
    case 'talentaccepted':
      statusColor = '#3de651';
      break;
    case 'declined request':
      statusColor = '#ff3c00';
      break;
    case 'optioned':
      statusColor = '#0060ff';
      break;
      case 'optionSolved':
        statusColor = '#FFFF00';
        break;
        case 'hold':
      statusColor = '#800080';
      break;
    case 'lano':
      statusColor = '#116c11';
      break;
      case 'Booked':
        statusColor = '#ADD8E6';
        break;
    default:
      statusColor = '#606060';
    }
    

    return (
      <div className="my-div"
        onMouseEnter={() => this.handleEventMouseEnter(event.id)}
        onMouseLeave={() => this.handleEventMouseLeave(event.id)}
      >
        {event.showDetail && (
          <div className='toltip'
            style={{
              position: 'absolute',
              width: "auto",
              height: "auto",
              top: "-300px",
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 5px 0px',
              left: "50px",
              background: '#000000',
              color: '#ffffff',
              padding: '10px',
              zIndex: 9999,
            }}
          >

            <p >Start Date: {moment(event.start).format('DD-MM-YYYY')}</p>
            <p>End Date: {moment(event.end).format('DD-MM-YYYY')}</p>
            <p>Client: {event.client} </p>
            <p>Kpi: {event.kpi} </p>
            <p>Option: {event.option} </p>
            <p>Segment: {event.segment} </p>
            {/* Add more details as needed */}
          </div>
        )}
        <div style={{ height: 20, width: '100%' }}>
          <div style={{
            height: '7px',
            width: '7px',
            padding: '10px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            float: 'right',
           
          }} />
        </div>
        <span style={{ marginTop: '30px', paddingTop: '60px', position: 'relative', color: 'black', height: 150, whiteSpace: 'pre-line' }}>
          {`${event.boktitle}\nClient: ${event.client}\nSet: ${event.set}\nKpi: ${event.kpi}\nTalent: ${event.talent} \nOption: ${event.option},\nStatus: ${event.status},`}
        </span>

        <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>

          <a href={`edit?bookingId=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline' }}>

            <img src={edit} alt="Edit" className="edit-container" />
          </a>
        </div>
      </div>
    );
  }



  WeekEvent = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
      case 'lano':
        statusColor = '#116c11';
        break;
        case 'hold':
          statusColor = '#800080';
          break;
          case 'Booked':
            statusColor = '#ADD8E6';
            break;
      default:
        statusColor = '#606060';
    }
    let borderRadius;
    switch (event.set) {
      case 'On Model':
        borderRadius = '10px';
        break;
      case 'on Produkt':
        borderRadius = '0px';
        break;
    }

    return (
      <div style={{ border: '1px solid black', margin:'3px',  padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px', minHeight: '110px', cursor: 'auto', borderRadius:borderRadius }}
        onMouseEnter={() => this.handleEventMouseEnter(event.id)}
        onMouseLeave={() => this.handleEventMouseLeave(event.id)}
      >
        {event.showDetail && (
          <div className='toltip'
            style={{
              position: 'absolute',
              width: "600px",
              height: "auto",
              top: "10px",
              left: "10px",
              background: '#000000',
              color: '#ffffff',
              padding: '10px',
              zIndex: 9999,
              cursor: 'auto'
            }}
          >
                            


            <span style={{ position: 'relative', fontWeight: '600', color: '#fff', whiteSpace: 'pre-line' }}>
              <div class="calendar-inner-cell-container">
                <div>

                  {` ${event.client} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Talent `}
                  </div>
                  {` ${event.talent} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Status `}
                  </div>
                  {` ${event.status} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Proposed By: `}
                  </div>
                  {` ${event.proposedBy} `}
                </div>
                <div>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Title `}
                  </div>
                  {` ${event.boktitle} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Set `}
                  </div>
                  {` ${event.set} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Kpi `}
                  </div>
                  {` ${event.kpi} `}
                </div>
                <div style={{ flexGrow: '1' }}>
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                    {` Option `}
                  </div>
                  {` ${event.option} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` Start time `}
                  </div>
                  {` ${moment(event.start).format('DD-MM-YYYY')} `}
                  <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                    {` End time `}
                  </div>
                  {` ${moment(event.end).format('DD-MM-YYYY')} `}

                </div>
              </div>
         {/*     <a href={`edit?bookingId=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', float: 'right', }}>
                <img src={editWhite} alt="Edit" className="edit-container" />
              </a>

          */}
            </span>

          </div>
        )}
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
        </div>
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.startTime}-  ${event.endTime}`}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.client} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.talent} `}</span>

          <span style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '83%', display: 'inline-block', whiteSpace: 'nowrap' }}>{` ${event.proposedBy} `}</span>
        </span>

    {/*    <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>

          <a href={`edit?bookingId=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', float: 'right', }}>
            <img src={edit} alt="Edit" className="edit-container" />
          </a>
        </div>
        */}
      </div>
    );
  }

  AgendaEvent = ({ event }) => {
    let statusColor;
    switch (event.status) {
      case 'confirmed':
        statusColor = '#15a326';
        break;
      case 'new order':
        statusColor = '#ffffff';
        break;
        case 'requested':
        statusColor = '#FF8806';
        break;
      case 'talentaccepted':
        statusColor = '#3de651';
        break;
      case 'declined request':
        statusColor = '#ff3c00';
        break;
      case 'optioned':
        statusColor = '#0060ff';
        break;
        case 'optionSolved':
          statusColor = '#FFFF00';
          break;
          case 'hold':
            statusColor = '#800080';
            break;
            case 'Booked':
              statusColor = '#ADD8E6';
              break;
      case 'lano':
        
        statusColor = '#116c11';
        break;
      default:
        statusColor = '#606060';
    }

    return (
      <div style={{ border: '1px solid black', margin:'3px',  padding: '10px', position: 'relative', color: '#000000', borderTopRightRadius: '10px', minHeight: '103px', marginBottom: '10px' }}>
        <div>
          <span style={{
            height: '22px',
            width: '22px',
            backgroundColor: statusColor,
            borderRadius: '50%',
            display: 'inline-block',
            position: 'absolute',
            top: '10px',
            right: '10px'
          }} />
                    

        </div>
        <span style={{ position: 'relative', fontWeight: '600', color: '#000000', whiteSpace: 'pre-line' }}>
          <div class="calendar-inner-cell-container">
            <div>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Client `}
              </div>
              {` ${event.client} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Talent `}
              </div>
              {` ${event.talent} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Status `}
              </div>
              {` ${event.status} `}
            </div>
            <div>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Title `}
              </div>
              {` ${event.boktitle} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Set `}
              </div>
              {` ${event.set} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Kpi `}
              </div>
              {` ${event.kpi} `}
            </div>
            <div style={{ flexGrow: '1' }}>
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginBottom: '2px' }}>
                {` Option `}
              </div>
              {` ${event.option} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` Start time `}
              </div>
              {` ${moment(event.start).format('DD-MM-YYYY')} `}
              <div style={{ fontWeight: '300', fontSize: '.8rem', marginTop: '5px', marginBottom: '2px' }}>
                {` End time `}
              </div>
              {` ${moment(event.end).format('DD-MM-YYYY')} `}
            </div>
          </div>
        </span>

     {/*   <div style={{ position: 'absolute', right: 0, bottom: 0, paddingRight: '10px', paddingBottom: '10px' }}>
          <a href={`edit?bookingId=${event.bookingId}`} style={{ color: 'blue', textDecoration: 'underline', float: 'right', }}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 402.04 392.38" style={{ enableBackground: 'new 0 0 402.04 392.38', width: '20px', height: 'auto' }}>
              <path d="M388.6,83.84l-72.62-70.88c-7.43-7.14-17.34-11.13-27.65-11.13c-10.31,0-20.22,3.99-27.65,11.13L49.63,218.93  c-1.67,1.9-2.98,4.1-3.85,6.48L3.43,350.89c-1.85,5.34-2.16,11.09-0.89,16.61c1.27,5.51,4.06,10.55,8.07,14.54  c4.09,4.08,9.22,6.97,14.83,8.36c5.61,1.39,11.49,1.22,17.02-0.48l128.1-40.95c1.87-0.64,3.6-1.66,5.07-2.98h1.4l211.58-207.02  c7.47-7.22,11.7-17.17,11.7-27.56C400.3,101.01,396.08,91.07,388.6,83.84L388.6,83.84z M166,307.84l-79.1-76.3l152.77-149.1  l78.4,76.48L166,307.84z M39.12,354.04l30.45-90.82l62.65,61.07L39.12,354.04z M364.1,113.77l-20.82,20.48l-78.4-76.48l20.3-19.77v0  c0.82-0.86,1.96-1.34,3.15-1.34s2.32,0.48,3.15,1.34l72.63,71.05c0.8,0.55,1.29,1.47,1.29,2.45c0,0.98-0.48,1.89-1.29,2.45  L364.1,113.77z" />
            </svg>
          </a>
        </div>
        */}
      </div>
    );
  }

  switchView = (view) => {
    this.setState({ view });
}
  render() {
    console.log("Rendering with events:", this.state.events); // 5. Inside Rendering
    if (this.state.loading) {
      return <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>;
    }
    const { userList, selectedUserEmail, loading } = this.state;
    const options = userList.map(user => ({
      value: user.email,
      label: user.name
    }));
    const bookingStatusOptions = [
      { value: 'All', label: 'All' },
      { value: 'Booked', label: 'booked / Lano task created' },
      { value: 'confirmed', label: 'confirmed' },
      { value: 'new order', label: 'new order' },
      { value: 'requested', label: 'requested' },
      { value: 'declined request', label: 'declined request' },
      { value: 'optioned', label: 'optioned' },
      { value: 'optionSolved', label: 'option released' },

      // ... other statuses
    ];

    return (
      <section style={{   display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'}}>
      <div class="content-wrapper" style={{  flex: 1,  overflowY: 'auto', paddingBottom: '30px' }}>
          <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '60px' }}>
            <h1>Orders & bookings</h1>
            <div>
            <a href = 'allgroups'><button className='big-button'>Groups</button></a>
            <button className={this.state.view === 'calendar' ? 'big-button' : 'big-button-active'} onClick={() => this.switchView('list')} style={{ marginLeft: 'auto' }}>List View</button>
            <button className={this.state.view === 'calendar' ? 'big-button-active' : 'big-button'} onClick={() => this.switchView('calendar')}>Calendar View</button>
            </div>        
      
          </div>

          {this.state.view === 'calendar' ? (
            <>
            <div className='input-container' style={{ paddingTop: '40px' }} >

               <div>
              <p style={{ marginBottom: '5px' }}>Filter by status</p>
                <Select
                  value={bookingStatusOptions.find(option => option.value === this.state.selectedBookingStatus)}
                  onChange={this.handleBookingStatusSelection}
                  options={bookingStatusOptions}
                  styles={customStyles}
                />
              </div>
              <div>
  <p>Filter by Location</p>
  <Select
      value={this.state.selectedLocation}
      onChange={this.handleLocationSelection}
      options={this.state.locationOptions}
      styles={customStyles}
      isClearable
  />
</div>
            </div>
    <Calendar
              localizer={localizer}
              events={this.state.filteredBookings} 
              startAccessor="start"
              views={['month', 'week', 'agenda']}

              popup
              style={{   flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible', height:5800}}  
              view={this.state.currentView}
              onSelectEvent={event => this.handleSelectEvent(event)}
              components={{
                event: this.Event,
                week: {
                  event: this.WeekEvent
                },
                agenda: {
                  event: this.AgendaEvent
                },
                month: {
                  // You can define a MonthEvent similar to WeekEvent if needed
                  event: this.MonthEvent || this.WeekEvent
                },
              
                toolbar: props => <CustomToolbar {...props} date={this.state.date} onNavigate={this.onNavigate}
                  onWeekChange={this.onWeekChange} date={this.state.currentDate} 
                  currentView={this.state.currentView}
                  onChangeView={this.changeView} />

              }}
              onNavigate={this.onNavigate}
              date={this.state.currentDate}
              currentView={this.state.currentView}
            />
  
 

</>
          ) : (
            < ListViewAllBookings events={this.state.events} authToken={this.props.authToken} />
        )}
          </div>
        </div>
      </section>
    );
  }
}

export default AllBookingsPage;
